@font-face {
	font-family: 'AllProSans';
	src: url('../fonts/All-ProSans-Regular.woff') format('woff');
	font-weight: normal;
}

@font-face {
	font-family: 'AllProDisplayC';
	src: url('../fonts/AllProDisplayC-Regular.woff') format('woff');
	font-weight: normal;
}

@font-face {
    font-family: 'AllProDisplayC';
    src: url('../fonts/AllProDisplayC-SemiBold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'AllProDisplayC';
    src: url('../fonts/AllProDisplayC-Black.woff') format('woff');
    font-weight: 900;
}
