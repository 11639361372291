/* autoprefixer grid: autoplace */

html, body {
	background: #585858;
	color: #FFF;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	font-family: 'AllProDisplayC', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100%;
}

#root {
    scroll-snap-type: y mandatory;
    overflow: auto;
    height: 100%;
}

* {
	-webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'AllProDisplayC', sans-serif;
    font-weight: normal;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 22px;
}

input,
button,
select {
	outline: none;
	font-family: Arial, sans-serif;
}
